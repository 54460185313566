import React, { Component } from 'react';

class getListaPedidos extends Component {
  constructor(props) {
      super(props);
      this.state = {
        id_filtro : 0 ,
        data : [] ,
        detalle : [],
        id_online_pedidos_activo : 0,
        id_cliente_tmp :0
      }
      this.handleChange = this.handleChange.bind(this);
      //this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({id_filtro: event.target.value,detalle : [],
    id_online_pedidos_activo : 0},
    () =>{this.consultarListaAPI()});

  }
  buscarDetalle = (id_online_pedidos) =>{
    const url = this.props.url_global + "webservices/getDetallePedido/"+id_online_pedidos;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
           this.setState({detalle :  data ,id_online_pedidos_activo:id_online_pedidos})
      })
      .catch(console.log)
  }
  encerar_detalle =() =>{
    this.setState({detalle :  [] ,id_online_pedidos_activo:0})
  }
  consultarListaAPI = () => {
    let url;
    const id_filtro = this.state.id_filtro;
    if(Number(id_filtro)===0){
       url = this.props.url_global + "webservices/getPedidosPendientes/"+this.props.id_usuario+"/"+this.props.id_cliente;
    }
    if(Number(id_filtro)===1){
       url = this.props.url_global + "webservices/getPedidosFacturados/"+this.props.id_usuario+"/"+this.props.id_cliente;
    }

    fetch(url)
      .then(res => res.json())
      .then((data) => {
         if(data[0].status==='1'){
           this.setState({data})
         }else{
           this.setState({data : []})
         }
      })
      .catch(console.log)
  }
  renderTableDetalle = () =>{
    const array_nota = this.state.detalle;
    return (

      <div className="divTable_Detalle tb_nota_Detalle">
      <div className="divTableHeading_Detalle">
        <div className="divTableRow_Detalle">
          <div className="divTableHead_Detalle">Cantidad</div>
          <div className="divTableHead_Detalle">Detalle</div>
          <div className="divTableHead_Detalle">Medida</div>
        </div>
      </div>
      {array_nota.map(nota => (
        <div className="divTableBody_Detalle" key={nota.id_detalle}>
          <div className="divTableRow_Detalle" >
            <div className="divTableCell_Detalle">{nota.cantidad}</div>
            <div className="divTableCell_Detalle">{nota.item}</div>
            <div className="divTableCell_Detalle">{nota.medida}</div>
          </div>
        </div>
      ))}
      </div>
    )
  }
  renderTableNota = () =>{
        const array_nota = this.state.data;
        return (
          array_nota.map(nota => (
            <div onClick={ () => this.buscarDetalle(nota.id_online_pedidos)} className="divTableBody" key={nota.id_online_pedidos}>
              <div className="divTableRow">
                <div className="divTableCell">{nota.estado}</div>
                <div className="divTableCell">{nota.secuencial}</div>
                <div className="divTableCell">{nota.fecha_creacion}</div>
                <div className="divTableCell">{nota.cliente}</div>
                <div className="divTableCell">{nota.factura}</div>
                <div className="divTableCell">{nota.fecha_factura}</div>
              </div>
            </div>
          ))
        )
  }

  render() {
    let loading;
    if(this.state.id_online_pedidos_activo){
      loading =<div id="loader_2" className="loader_2 is-active" data-text="">
                <div className="divTable_General">
                {this.renderTableDetalle()}
                <br/><br/><button className="button_final"  onClick={ () => this.encerar_detalle()}>Regresar</button>
                </div>
              </div>
    }

    return(
      <React.Fragment>
      {loading}
      <div className="div_contenedor">
      <span className="lbl_etiqueta">Lista de Pedidos:</span>
        <select className="select-css" value={this.state.id_filtro} onChange={(e) => {this.handleChange(e)}}>
          <option value="0">Pendientes</option>
          <option value="1">Facturadas</option>
        </select>


        <div className="divTable tb_nota">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead">Estado</div>
              <div className="divTableHead">Comprobante</div>
              <div className="divTableHead">Fecha Creación</div>
              <div className="divTableHead">Cliente</div>
              <div className="divTableHead">N Factura</div>
              <div className="divTableHead">Fecha Factura</div>
            </div>
          </div>
          {this.renderTableNota()}
          <br/>
        </div>

      </div>
      </React.Fragment>
    )
  }

  componentDidMount() {
    //this.actualizar_cuentas();
    this.consultarListaAPI();

  }
  componentDidUpdate (){
    const id_cliente_tmp = this.state.id_cliente_tmp;
    const id_cliente = this.props.id_cliente;
    if(id_cliente_tmp!==id_cliente){
      this.setState({
        id_cliente_tmp:id_cliente
      })
      this.consultarListaAPI();
    }
  }
}


/*  <div className="div_contenedor_nuevo">
     <span className="lbl_etiqueta">Nuevo Pedido: </span>
    <img  alt="Cargando ..." className="img_icon" onClick={(e) => {this.props.getNewModule(e)}} src={ require('../images/new.png') } />
  </div>*/

export default getListaPedidos;
