import React, { Component } from 'react';
import GetNewPedido from './getNewPedido';
import GetListaPedidos from './getListaPedidos';
import GetFinalizarCompra from './getFinalizarCompra';


class getHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modulo_activo : 'home',
      id_filtro : '0',
      items_pedidos : [],
      cantidad_carrito : 0 ,
      clientes : [] ,
      id_cliente : 0 ,
      id_item_activo : 0,
      sms_mensaje :'',
      nom_cliente :''
    }
  }
  getCrearPedido = () =>{
    const id_cliente = this.state.id_cliente;
    const items_pedidos = this.state.items_pedidos;
    const id_usuario = this.props.id_usuario;
    const nombre_maquina = this.props.nombre_maquina;
    if(items_pedidos.length === 0) return null;
    if(id_cliente>0){
    }else{
       alert('Seleccione Cliente');
      return null;
    }
    const url= this.props.url_global + "webservices/getCrearNuevoPedido";
    //var data = {username: 'example'};
    var obj = { accion:'grabar', items_pedidos, id_cliente ,id_usuario,nombre_maquina };
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(obj), // data can be `string` or {object}!
      headers:{
        'Accept':'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then((data) => {
      //console.log(data)
      if(data[0].status==="fail"){
        this.setState({
          sms_mensaje : data[0].sms
        })
      }
      if(data[0].status==="ok"){
        this.setState({
          items_pedidos : [] ,
          cantidad_carrito :0,
          id_item_activo : 0,
          sms_mensaje :''
        },() =>
        {
         this.getHomeModule();
        })
      }
      this.props.setLoadingClose();
    })
    .catch(console.log)
    this.props.setLoading();
  }

  agregarCarrito = (index, id , name, cantidad,precio_venta,iva) => {

  //  console.log(event.target.pageX);
    //console.log( "ss"+id);
    //ReactDOM.findDOMNode(this.refs['image_'+id]) .getBoundingClientRect();

      //console.log(cantidad[id]);
      let cantidad_actual=0;
      if(isNaN(cantidad[id])){
        cantidad_actual = 1 ;
      }else{
        cantidad_actual =  Number(cantidad[id]);
      }

      if(cantidad_actual <= 0){
        cantidad_actual = 1;
      }
      let cantidad_carrito=0;
      /*cantidad_carrito++;
      this.setState ({
        cantidad_carrito
      })*/


      this.setState(state => {
      const cartItems = state.items_pedidos;
      let productAlreadyInCart = false;

      cartItems.forEach(cp => {
        if (cp.id === id) {
          cp.count += cantidad_actual;
          productAlreadyInCart = true;
        }
        cantidad_carrito=cantidad_carrito+cp.count;
      });

      if (!productAlreadyInCart) {
        cartItems.push({id, name , count: cantidad_actual,precio_venta,iva });
        cantidad_carrito = cantidad_carrito + cantidad_actual;
      }
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { items_pedidos: cartItems, cantidad_carrito };
    });
    this.setState({
      id_item_activo: id
    })
    clearInterval(this.timer)
    this.timer = setInterval(this.tick.bind(this), 2000)
  }
  tick () {
    this.setState({
      id_item_activo: 0
    })
     clearInterval(this.timer);
   }

 getHomeModule = () => {
   this.setState({
     modulo_activo: 'home'
   })
 }
  eliminarCarrito = (id) => {
    let cantidad_carrito = 0;
    const cartItems = this.state.items_pedidos.filter(a => a.id !== id);
    localStorage.setItem('items_pedidos', JSON.stringify(cartItems));

    cartItems.forEach(cp => {
      cantidad_carrito=cantidad_carrito+cp.count;
    });

      this.setState({
        items_pedidos: cartItems, cantidad_carrito
      })
  }

  consultarAPI = () => {


    const url= this.props.url_global + "webservices/getClientes/"+this.props.id_vendedor;
    //  console.log(url);
      fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].id_empresa!=='0'){
          this.setState({ clientes: data })
        }
      })
      .catch(console.log)
  }

  getNewModule = () =>{
    this.setState({
      modulo_activo: 'new'
    })
  }

  getExitModule = () =>{
    this.setState({
      modulo_activo: 'home'
    } ,  () =>
      {
       this.props.salir();
      }
    )
  }

  handleChangeE(event) {
    //console.log(event.target.options[event.target.selectedIndex].text);

    this.setState({
      items_pedidos : [] ,
      cantidad_carrito :0,
      id_item_activo : 0,
      sms_mensaje :'',
      modulo_activo:'home',
      id_cliente: event.target.value,
      nom_cliente:event.target.options[event.target.selectedIndex].text
    });
  }

  mostrarClientes = () => {

    const clientes = this.state.clientes;
    let list_cliente = <React.Fragment></React.Fragment>;
    if(clientes.length !== 0){
      clientes.forEach(cliente => {
        const tmp = <option value={cliente.id_empresa}>{cliente.nom} {cliente.referencia_empresa}</option>
        list_cliente = list_cliente + tmp;
      })
    }

    return (
      <React.Fragment>
        <select className="select-css" value={this.state.id_cliente} onChange={(e) => {this.handleChangeE(e)}}>
          <option value="0">Seleccione Cliente</option>
          {(clientes.length !== 0) ?
            clientes.map(cliente => (
              <option key={cliente.id_empresa} value={cliente.id_empresa}>{cliente.nom} {cliente.referencia_empresa}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

      </React.Fragment>
    )
    //console.log("id_subrubro");
  }

  getFinalizar = () =>{
    const items = this.state.items_pedidos;
    let modulo_activo= this.state.modulo_activo;
    if(modulo_activo==='finalizar'||modulo_activo==='home'){
      modulo_activo='new';
    }else{
      if(items.length === 0) return null;
      modulo_activo='finalizar';
    }
    this.setState({
      modulo_activo
    })
  }

  render() {
    const modulo_activo =this.state.modulo_activo;
    let modulo;
    //console.log(modulo_activo);
    if (modulo_activo==='home') {
      modulo = <GetListaPedidos getNewModule={this.getNewModule} id_cliente={this.state.id_cliente} clientes={this.state.clientes} handleChangeCliente={this.handleChangeCliente} id_usuario={this.props.id_usuario} url_global={this.props.url_global} />;
    } else {
      if (modulo_activo==='finalizar') {
        modulo = <GetFinalizarCompra nom_cliente={this.state.nom_cliente} id_cliente={this.state.id_cliente} sms_mensaje={this.state.sms_mensaje} getCrearPedido={this.getCrearPedido} eliminarCarrito={this.eliminarCarrito} items_pedidos={this.state.items_pedidos}/>;
      }else{
        modulo = <GetNewPedido id_item_activo={this.state.id_item_activo} id_cliente={this.state.id_cliente} url_global={this.props.url_global} agregarCarrito={this.agregarCarrito} eliminarCarrito={this.eliminarCarrito} items_pedidos={this.state.items_pedidos}/>;
      }
    }
/*<label></label><img alt="Cargando ..." className="img_icon" src={ require('../images/car.png') } />
*/
    return (
      <div className="grid-container-pedido">
        <div className="lbl_menu">
          <div className="grid-container-menu">
            <div className="lbl_home">
            <img  alt="Cargando ..." className="img_icon" onClick={ () => this.getHomeModule()} src={ require('../images/home.png') } />
            {this.props.empresa}
            </div>
            <div className="lbl_carrito">
            <div className="grid-container-carrito" onClick={ () => this.getFinalizar()}>
              <div>
                <img className="img_icon_top"  alt="Cargando ..."
                src={ this.state.id_item_activo!==0 ? require('../images/daily-ui-058.gif'): require('../images/car.png')} />
              </div>
              <div className="lbl_icono">
                {this.state.cantidad_carrito}
              </div>
            </div>

            </div>
            <div className="lbl_otros">

            </div>
            <div className="lbl_salida"><img alt="Cargando ..." className="img_icon" onClick={ () => this.getExitModule()} src={ require('../images/salir.png') } /></div>
          </div>
        </div>
        <div className="lbl_pedido">
        <div className="lbl_filtros">
          Usuario Activo :{this.props.usuario}
          {this.mostrarClientes()}
        </div>
        <div className="lbl_lista_pedidos">
          {modulo}
        </div>


        </div>
      </div>
    );
  }

  componentDidMount (){
    this.consultarAPI();
  }


}
export default getHome;
