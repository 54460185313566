import React, { Component } from 'react';

class getFinalizarCompra extends Component {


  eliminarCantidad = (index) => {
    var items_cantidad = this.state.items_cantidad.slice();
    if(isNaN(items_cantidad[index])){
      items_cantidad[index] = 1;
    }else{
      items_cantidad[index] = items_cantidad[index] - 1;
      if(items_cantidad[index]<=0){
        items_cantidad[index] = 1;
      }
    }
    this.setState({items_cantidad});
  }

 mostrarItemsSeleccionados = () => {
   const items = this.props.items_pedidos;
   const roundTo = require('round-to');
   if(items.length === 0) return null;
   let sms_final ;

   if(this.props.sms_mensaje!==''){
     sms_final= <React.Fragment>
               {this.props.nom_cliente} <br/> <br/>
               {this.props.sms_mensaje} <br/> <br/>
                </React.Fragment>
   }

   let total_pedido = 0;
   if(items.length !== 0){
     items.forEach(item => {
       total_pedido = total_pedido +roundTo( (Number(item.precio_venta) +(Number(item.precio_venta)*item.iva)/100)*Number(item.count) ,2)
     })
   }
   total_pedido=roundTo(total_pedido,2);

   return (
     <React.Fragment>
      <div className="titleS">
        Productos Seleccionados
      </div>
         {items.map(item => (
           <div className="grid-container-item" key={"key_item_"+item.id}>
            <div className="lbl_cantidad">
              <div className="quantity">
                <label>{item.count}</label>
              </div>
            </div>
            <div className="lbl_descripcion">
              <div className="description">
                 <label>{item.name} ({roundTo(Number(item.precio_venta),2)}$+{item.iva} IVA)</label>
              </div>
            </div>
            <div className="lbl_button">
              <div className="image_">
               <img alt="Cargando ..." className="img_icon" onClick={ () => this.props.eliminarCarrito(item.id)} src={ require('../images/eliminar.png') } />
              </div>
            </div>
          </div>

         ) ) }

         <div className="div_contenedor_nuevo">

         Total Proforma: <b>{total_pedido}</b><br/>
         Estoy de acuerdo con la lista de Artículos: <br/>
         <span className="lbl_mensaje_cierre">
         {sms_final}
         </span>
         <button className="button_final"  onClick={ () => this.props.getCrearPedido()}>Realizar Pedido</button>
         </div>
     </React.Fragment>
   )
 }

  render() {

    return (
       <React.Fragment>
        <div className="shopping-cart">
          {this.mostrarItemsSeleccionados()}
        </div>
       </React.Fragment>
    );
  }

}
export default getFinalizarCompra;
