import React, { Component } from 'react';


class getNewPedido extends Component {
  constructor(props) {
    super(props);
    const items =  []  ;
    /*{
        Crubro: 'Latas',
        Citems: [{
          id: '1',
          component: 'Lata 100ml'
        }, {
          id: '2',
          component: 'Lata 3000ml',
        }]
      },{
      Crubro: 'Barriles',
      Citems: [{
        id: '3',
        component: 'Barriles 40lt'
      }, {
        id: '4',
        component: 'Barriles 10lt',
      }]
    }];
    console.log((JSON.stringify(items)));*/
    this.state = {
      items, items_cantidad : []
    }
    //this.handleChange = this.handleChange.bind(this);
}

consultarItemAPI = () => {
  const id_cliente = this.props.id_cliente;
  if(id_cliente>0){
    const url= this.props.url_global + "webservices/PgetProductos/"+id_cliente;
    //console.log(url)
    fetch(url)
      .then(res => res.json())
      .then((data) => {
         this.setState({items: data})
      })
      .catch(console.log)
  }
}

agregarCantidad = (index) => {

  var items_cantidad = this.state.items_cantidad.slice();
  if(isNaN(items_cantidad[index])){
    items_cantidad[index] = 1;
  }else{
    items_cantidad[index] = items_cantidad[index] + 1;
  }
  this.setState({items_cantidad});
}

eliminarCantidad = (index) => {
  var items_cantidad = this.state.items_cantidad.slice();
  if(isNaN(items_cantidad[index])){
    items_cantidad[index] = 1;
  }else{
    items_cantidad[index] = items_cantidad[index] - 1;
    if(items_cantidad[index]<=0){
      items_cantidad[index] = 1;
    }
  }
  this.setState({items_cantidad});
}

handleChange = (event , index) => {
  var items_cantidad = this.state.items_cantidad.slice(); // Make a copy of the emails first.
  if(isNaN(event.target.value)){
    items_cantidad[index] = 1;
  }else{
    items_cantidad[index] = event.target.value;
  }
   // Update it with the modified email.
  this.setState({items_cantidad});
  //console.log(items_cantidad);
}


 mostrarItemsSeleccionados = () => {
   const roundTo = require('round-to');
   //let costo =  roundTo(Number(pfinal)* Number(cantidad),2);

   const items = this.props.items_pedidos;
   if(items.length === 0) return null;
   return (
     <React.Fragment>
      <div className="titleS">
        Productos Seleccionados
      </div>
         {items.map(item => (
           <div className="grid-container-item" key={"key_item_"+item.id}>
            <div className="lbl_cantidad">
              <div className="quantity">
                <label>{item.count}</label>
              </div>
            </div>
            <div className="lbl_descripcion">
              <div className="description">
                 <label>{item.name} ({roundTo(Number(item.precio_venta),2)}$+{item.iva} IVA)</label>
              </div>
            </div>
            <div className="lbl_button">
              <div className="image_">
               <img alt="Cargando ..." className="img_icon"  onClick={ () => this.props.eliminarCarrito(item.id)} src={ require('../images/eliminar.png') } />
              </div>
            </div>
          </div>

         ) ) }

     </React.Fragment>
   )
 }

  mostrarItems = () => {
    const roundTo = require('round-to');
     const items = this.state.items;
     if(items.length === 0) return null;
     return (
       <React.Fragment>
         {items.map((item , index) => (
           <div key={"key_rubro_"+item.Crubro}>
              <div className="title">
                {item.Crubro}
              </div>
              {item.Citems.map((ditem , dindex) => (
                <div className="grid-container-item" key={"key_item_"+ditem.id}>
                  <div className="lbl_cantidad">
                    <div className="quantity">
                      <button className="plus-btn" onClick={ () => this.agregarCantidad(ditem.id) }>+</button>
                      <input  type="text" name={"item_"+ditem.id} value={this.state.items_cantidad[ditem.id]|| ''} onChange={(e) => {this.handleChange( e , ditem.id )}} />
                      <button className="plus-btn" onClick={ () => this.eliminarCantidad(ditem.id)}>-</button>
                    </div>
                  </div>
                  <div className="lbl_descripcion">
                    <div className="description">
                     <span>{ditem.component} ({roundTo(Number(ditem.precio_venta),2)}$+{ditem.iva} IVA)</span>
                    </div>
                  </div>
                  <div className="lbl_button">
                    <div className="image_">
                     <img className="img_icon" ref={"image_"+ditem.id} alt="Cargando ..."
                     onClick={ () => this.props.agregarCarrito(this,ditem.id,ditem.component,this.state.items_cantidad,ditem.precio_venta,ditem.iva)}
                     src={ this.props.id_item_activo===ditem.id ? require('../images/daily-ui-058.gif'): require('../images/car.png')} />
                    </div>
                  </div>
                </div>
              ))}
           </div>
         ) ) }
       </React.Fragment>
     )
   }

  render() {

    return (
       <React.Fragment>
        <div className="shopping-cart">
          {this.mostrarItems()}
        </div>
        <div className="shopping-cart">
          {this.mostrarItemsSeleccionados()}
        </div>
       </React.Fragment>
    );
  }

  componentDidMount() {
    //this.actualizar_cuentas();
    this.consultarItemAPI();
  }
}
export default getNewPedido;
