import React, { Component } from 'react';

class getConfiguracion extends Component {

  constructor(){
    super();
    this.inputChangedHandlerM = this.inputChangedHandlerM.bind(this);
    this.inputChangedHandlerU = this.inputChangedHandlerU.bind(this);
    this.handleChangeE = this.handleChangeE.bind(this);
  }
  handleChangeE= (event) => {
    this.props.setIdCaja (event.target.value);
  }
  mostrarCajas = () => {

    const cajas = this.props.cajas;

    if(cajas.length === 0) return null;
    return (
      <React.Fragment>
        <select className="css-cantidad-configuracion" value={this.props.id_caja} onChange={(e) => {this.handleChangeE(e)}}>
          <option value="0">Seleccionar Caja</option>
          {cajas.map(caja => (
            <option value={caja.id_caja} key={caja.id_caja}>{caja.caja}</option>
          )) }
        </select>
      </React.Fragment>
    )
    //console.log("id_subrubro");
  }

  inputChangedHandlerM = (event) => {
    this.props.setMaquina(event.target.value);
  }
  inputChangedHandlerU = (event) => {
    this.props.setURL(event.target.value);

  }
  setConfiguracion = () =>{
      const nombre_maquina = this.props.nombre_maquina.trim();
      const url_global = this.props.url_global.trim();
      const id_caja = Number(this.props.id_caja);
      if(nombre_maquina===''){
        alert('Ingrese Nombre de Máquina');
          return null;
      }
      if(url_global===''){
        alert('Ingrese Nombre de URL');
          return null;
      }
      if(id_caja<=0){
        alert('Seleccione Caja');
          return null;
      }
      this.props.setConfiguracion();
  }

  render (){
    return (
      <React.Fragment>
      <font className="css-label-configuracion">Bienvenido/a al Módulo de Pedidos On-line Neola v1</font>
        <table className="css-table-configuracion">
          <tbody>
            <tr>
              <td>
                Caja:
              </td>
              <td>
                {this.mostrarCajas()}
              </td>
            </tr>
            <tr>
              <td>
                Máquina:
              </td>
              <td>
                <input className="css-cantidad-configuracion" placeholder="MÁQUINA" type="text" value={this.props.nombre_maquina}
                onChange={(event)=>this.inputChangedHandlerM(event)} />
              </td>
            </tr>
            <tr>
              <td>
                Url:
              </td>
              <td>
                <input className="css-cantidad-configuracion" placeholder="URL GLOBAL" type="text" value={this.props.url_global}
                onChange={(event)=>this.inputChangedHandlerU(event)} />
              </td>
            </tr>
            <tr>
              <td colSpan ="2">
                <button className="btn-configuracion" onClick={ () => this.setConfiguracion()} >Grabar Configuración</button>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  componentDidMount() {
    //this.props.getCajaAPI();
  }

}
export default getConfiguracion;
